<template>
    <div>
        <el-form :model="formData" :rules="formRules" ref="form" size="mini">
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item
                        :label="'开始时间'"
                        prop="beginTime"
                    >
                        <el-date-picker
                            v-model="formData.beginTime"
                            style="width: 100%"
                            type="date"
                            :placeholder="'请选择'"
                            value-format="yyyy-MM-dd"
                        ></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="'结束时间'" prop="endTime">
                        <el-date-picker
                            v-model="formData.endTime"
                            style="width: 100%"
                            type="date"
                            :placeholder="'请选择'"
                            value-format="yyyy-MM-dd"
                        ></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
export default {
    name: 'TriggerForm',
    props: {
        id: {
            type: String,
            default: ''
        },
        url: {
            type: Function,
            default: null
        },
        closeModal: {
            type: Function
        }
    },
    data() {
        return {
            formData: {
                beginTime: '',
                endTime: ''
            },
            formRules: {
                beginTime: [
                    {
                        required: true,
                        message: '请选择开始时间',
                        trigger: 'blur'
                    }
                ],
                endTime: [
                    {
                        required: true,
                        message: '请选择结束时间',
                        trigger: 'blur'
                    }
                ]
            }
        };
    },

    methods: {
        submitHandle(cb) {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.url(this.formData).then(() => {
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                        cb();
                    });
                }
            });
        }
    }
};
</script>
<style lang="less" scoped>
</style>

