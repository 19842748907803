<template>
    <div class="ui-height-100" style="height:calc(100% - 20px);">
        <el-card :style="{margin: '20px 20px 20px 20px'}">
            <div slot="header" class="clearfix">
                <span
                    class="typefaceStyle"
                >{{'特业定时任务触发'}}</span>
            </div>
            <div class="body-conteiner">
                <el-row :gutter="24">
                    <el-col :md="8" :xl="6">
                        <div @click="technicCheckFlightMatching" class="itemStyle">
                            <div class="textStyle">{{'技术检查航班匹配任务'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div @click="technicCheckSetResultTask" class="itemStyle">
                            <div class="textStyle">{{'技术检查结果录入提醒'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div @click="calculateAerodromePracticeFlightTask" class="itemStyle">
                            <div class="textStyle">{{'计算本场训练结果'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div @click="sendAerodromePracticeResultToTrainSystemTask"
                             class="itemStyle">
                            <div class="textStyle">{{'通知本场训练的结果给训练系统'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div @click="getEmployeeInfoTask" class="itemStyle">
                            <div class="textStyle">{{'同步教育/工作经历'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div @click="day120Hous90CalculateTask" class="itemStyle">
                            <div class="textStyle">{{'120天100小时报表定时任务'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div @click="daysFlightNumsTask" class="itemStyle">
                            <div class="textStyle">{{'90天3次起落报表定时任务'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div @click="flyExperienceMonitorTask" class="itemStyle">
                            <div class="textStyle">{{'经历监控定时任务'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div @click="updateTrainsFromTranSystem" class="itemStyle">
                            <div class="textStyle">{{'同步培训系统的大纲'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div @click="userCredentialTask" class="itemStyle">
                            <div class="textStyle">{{'同步体检合格证信息'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div @click="alcoholTesterTask" class="itemStyle">
                            <div class="textStyle">{{'同步所有酒精检测数据'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div @click="stopRecoverFlyTask" class="itemStyle">
                            <div class="textStyle">{{'检查所有停飞信息'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div @click="personAuthorizationTask" class="itemStyle">
                            <div class="textStyle">{{'更新人员授权状态'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div @click="virtualRechargeTask" class="itemStyle">
                            <div class="textStyle">{{'虚拟充值定时任务'}}</div>
                        </div>
                    </el-col>

                    <el-col :md="8" :xl="6">
                        <div @click="autoCreatAdministrativeTask" class="itemStyle">
                            <div class="textStyle">{{'定时生成行政班'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div @click="checkToBeReleasedScheduleTask" class="itemStyle">
                            <div class="textStyle">{{'定时检验次日未发布排班及动态'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div @click="setUserCredentialStatus" class="itemStyle">
                            <div class="textStyle">{{'更新人员凭证状态'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div @click="autoCreateMaintainScheduleTask" class="itemStyle">
                            <div class="textStyle">{{'按照车间轮换生成维修人员业务班'}}</div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-card>
        <el-card :style="{margin: '0 20px 0 20px'}">
            <div slot="header" class="clearfix">
                <span class="typefaceStyle">{{'AOC同步任务触发'}}</span>
            </div>
            <div class="body-conteiner">
                <el-row :gutter="24">
                    <el-col :md="8" :xl="6">
                        <div @click="staffToAoc" class="itemStyle itemStyle--aoc">
                            <div class="textStyle">{{'同步所有特业人员的信息'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div @click="credentialToAoc" class="itemStyle itemStyle--aoc">
                            <div
                                class="textStyle"
                            >{{'同步所有凭证基础信息'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div @click="userCredentialToAoc" class="itemStyle itemStyle--aoc">
                            <div
                                class="textStyle"
                            >{{'同步一段时间里的用户凭证信息'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div @click="crewSchedulePostToAoc" class="itemStyle itemStyle--aoc">
                            <div
                                class="textStyle"
                            >{{'同步所有号位'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div @click="groupFromOrPmsByTime" class="itemStyle itemStyle--aoc">
                            <div class="textStyle">{{'同步一段时间里的组环信息'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div
                            @click="flightScheduleFromOrPmsByTime"
                            class="itemStyle itemStyle--aoc"
                        >
                            <div
                                class="textStyle"
                            >{{'同步一段时间里的排班信息'}}</div>
                        </div>
                    </el-col>
                    <el-col :md="8" :xl="6">
                        <div
                            @click="aircrewDynamicFromOrpmsByTime"
                            class="itemStyle itemStyle--aoc"
                        >
                            <div
                                class="textStyle"
                            >{{'同步一段时间里的机组动态信息'}}</div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-card>
        <el-dialog
            width="700px"
            :title="dialog.title"
            v-if="dialog.visible"
            :visible.sync="dialog.visible"
            :modal-append-to-body="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            :show-close="true"
        >
            <trigger-form ref="editForm" :id="dialog.currentId" :url="dialog.url"></trigger-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModalHandle" size="mini">
                    {{dialog.type === 'detail' ? '关闭' : '取消'}}
                </el-button>
                <el-button
                    type="primary"
                    @click="submitHandle"
                    size="mini"
                    v-if="dialog.type !== 'detail'"
                >{{'确定'}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import TriggerForm from './TriggerForm';
export default {
    name: 'Trigger',
    data() {
        return {
            dialog: {
                visible: false,
                title: '',
                currentId: '',
                url: ''
            }
        };
    },
    components: {
        TriggerForm
    },
    methods: {
        closeModalHandle() {
            this.dialog.visible = false;
        },
        technicCheckFlightMatching() {
            this.$client
                .technicCheckFlightMatching()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        technicCheckSetResultTask() {
            this.$client
                .technicCheckSetResultTask()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        calculateAerodromePracticeFlightTask() {
            this.$client
                .calculateAerodromePracticeFlightTask()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        sendAerodromePracticeResultToTrainSystemTask() {
            this.$client
                .sendAerodromePracticeResultToTrainSystemTask()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        getEmployeeInfoTask() {
            this.$client
                .getEmployeeInfoTask()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        day120Hous90CalculateTask() {
            this.$client
                .day120Hous90CalculateTask()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        daysFlightNumsTask() {
            this.$client
                .daysFlightNumsTask()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        flyExperienceMonitorTask() {
            this.$client
                .flyExperienceMonitorTask()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        updateTrainsFromTranSystem() {
            this.$client
                .updateTrainsFromTranSystem()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        userCredentialTask() {
            this.$client
                .userCredentialTask()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        autoCreatAdministrativeTask() {
            this.$client
                .autoCreatAdministrativeTask()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        checkToBeReleasedScheduleTask() {
            this.$client
                .checkToBeReleasedScheduleTask()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        setUserCredentialStatus() {
            this.$client
                .setUserCredentialStatus()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        alcoholTesterTask() {
            this.$client
                .alcoholTesterTask()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        stopRecoverFlyTask() {
            this.$client
                .stopRecoverFlyTask()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        personAuthorizationTask() {
            this.$client
                .personAuthorizationTask()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        virtualRechargeTask() {
            this.$client
                .virtualRechargeTask()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        staffToAoc() {
            this.$client
                .staffToAoc()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        credentialToAoc() {
            this.$client
                .credentialToAoc()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        userCredentialToAoc() {
            this.dialog = {
                title: '触发条件',
                currentId: undefined,
                visible: true,
                url: this.$client.userCredentialToAoc
            };
        },
        crewSchedulePostToAoc() {
            this.$client
                .crewSchedulePostToAoc()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        autoCreateMaintainScheduleTask() {
            this.$client
                .autoCreateMaintainScheduleTask()
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('common.select.failed'),
                        type: 'warning'
                    });
                });
        },
        groupFromOrPmsByTime() {
            this.dialog = {
                title: '触发条件',
                currentId: undefined,
                visible: true,
                url: this.$client.groupFromOrPmsByTime
            };
        },
        flightScheduleFromOrPmsByTime() {
            this.dialog = {
                title: '触发条件',
                currentId: undefined,
                visible: true,
                url: this.$client.flightScheduleFromOrPmsByTime
            };
        },
        aircrewDynamicFromOrpmsByTime() {
            this.dialog = {
                title: '触发条件',
                currentId: undefined,
                visible: true,
                url: this.$client.aircrewDynamicFromOrpmsByTime
            };
        },
        submitHandle() {
            this.$refs.editForm.submitHandle(() => {
                this.closeModalHandle();
            });
        }
    }
};
</script>
<style lang="less" scoped>
.body-conteiner {
    display: flex;
    flex-wrap: wrap;
    .itemStyle {
        height: 68px;
        width: 300px;
        margin-left: 45px;
        margin-bottom: 15px;
        margin-top: 15px;
        background-image: url('~@/assets/images/Mask.png');
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: left center;
        border: 1px solid #dcdfe6;
        box-shadow: 0px 6px 22px 0px rgba(208, 208, 208, 0.41);
        border-radius: 8px;
        &--aoc {
            background-image: url('~@/assets/images/Mask_Aoc.png');
        }
        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
        .textStyle {
            padding-left: 90px;
            line-height: 70px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
        }
    }
}

.typefaceStyle {
    font-weight: bold;
    font-size: 16px;
    margin-left: 20px;
    padding-left: 15px;
}
</style>
